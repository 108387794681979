<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>Completed</h1>
    </div>
    <div class="vap-page-content">
      <div v-if="tableData.Rows" class="">
        <div v-if="tableData.Rows.Values && tableData.Rows.Values.length > 0" class="main-table-container vasion-html-table-default">
          <VasionTable
            :headerColumns="tableData.Columns.Values"
            :hideColumns="['DocumentID', 'SignatureDocumentID']"
            :tableRows="tableData.Rows.Values"
            :supportSorting="true"
            @vasion-selection="handleRowSelect"
            @vasion-column-sort="sortColumns"
          />
        </div>
        <div v-else class="empty">
          <VasionDoneAllIconScalable id="done-icon" />
          <div class="text-style-1">
            You don’t have any completed documents
          </div>
          <div>
            Documents you complete will appear here.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tableSorterWithTransform } from '@/store/helperModules/common.module'

export default {
  name: 'TheSignatureCompleted',
  data: function () {
    return {
      tableData: []
    }
  },
  async created() {
    await this.$store.dispatch('digitalSignature/getSignatureDocumentsCompleted')
    this.refreshTableData()
  },
  methods: {
    async handleRowSelect(selectInfo) {
      if (selectInfo.Values && selectInfo.Values[0] && selectInfo.Values[0] !== '' && selectInfo.Values[0] !== '0') {
        const documentId = selectInfo.Values[0]
        const [, shouldOpenInDataView] = await Promise.all([
          this.$store.dispatch('document/setBackRoute', '/digitalSignature/completed'),
          this.$store.dispatch('workflow/shouldOpenInDataView', documentId),
        ])
        if (shouldOpenInDataView === true) {
          this.$router.push({ name: 'DocumentData', params: { documentID: documentId } })
        } else {
          this.$router.push({ name: 'Document', params: { documentID: documentId } })
        }
      }
    },
    refreshTableData(tableData) {
      if (tableData) {
        this.$store.commit('digitalSignature/setSignatureDocumentsCompleted', tableData)
      }

      this.tableData = this.$store.state.digitalSignature.completedData
    },
    async sortColumns(options) {
      let columnLegend = {
        'Subject': 2,
        'Primary ID': 3,
        'Signed By': 4,
        'Status': 5,
        'Date I Signed': 6,
        'Completed Data': 7,
      }

      let tableDataCopy = JSON.parse(JSON.stringify(this.tableData))
      tableDataCopy.Rows.Values.sort(tableSorterWithTransform(
        x => x.Values[columnLegend[options.columnName]],
        options.ascending
      ))

      this.refreshTableData(tableDataCopy)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/light-theme-system-palette.scss';

.text-style-1 {
  text-align: center;
  margin: 10px;
}

.empty {
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#done-icon {
  fill: $outline;
  margin-bottom: 40px;
}
</style>